import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { StorageService } from '../storage.service';
import { ToastService } from '../toast/toast.service';
import { UserService } from '../user.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, concatMap, delay, lastValueFrom, Observable, tap, throwError } from 'rxjs';
import { User } from 'src/app/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiUrl: string = environment.api;
  loggedIn = false;

  constructor(
    private router: Router,
    private storage: StorageService,
    private toast: ToastService,
    private userService: UserService,
    private http: HttpClient,
  ) {
  }


  // Get user session
  async getCurrentUser() {
    return await this.storage.get('user') as User;
  }

  signIn(login) {
    return this.http.post(
      this.apiUrl + 'auth',
      login
    )
      .pipe(
        tap(async (res: any) => { await this.saveToken(res) }),
        delay(700),
        concatMap(res => this.http.get(this.apiUrl + 'api/users/me')),
        tap(async (res: any) => { await this.saveUser(res) }),
      )
  }

  // Sign in google
  async signInWithGoogle() {
    let user;
    try {
      user = await GoogleAuth.signIn();
      return this.userService.createIfNotExistFromSocial('google', user?.authentication?.accessToken).subscribe(async loggedUser => {

        await this.storage.set('user', loggedUser);
        this.loggedIn = true;
        return this.loggedIn;
      },
        (error) => {
          this.toast.error('', JSON.stringify(error), 5000);
        }
      )

    } catch (e) {
      this.toast.error('', e.message, 5000);
      this.loggedIn = false;
    }
    return this.loggedIn;
  }

  // Sign out
  async signOut() {

    // ...
    // clean subscriptions / local storage etc. here
    // ...

    // Navigate to login
    this.router.navigateByUrl('/signin');
  }

  async isLoggedIn() {
    await this.storage.init();
    const currentUser = await this.storage.get('user');
    const token = await this.storage.get('token');
    this.loggedIn = currentUser !== undefined && token !== undefined;
    // try {
    //   await GoogleAuth.refresh();
    //   this.loggedIn = true;
    // } catch (e) {
    //   this.loggedIn = false;
    //   console.log(e);
    // }
    return this.loggedIn;
  }

  async signout() {
    await this.storage.clear();
    window.dispatchEvent(new Event("user:signout"));
    // try {
    //   await GoogleAuth.signOut();
    //   this.storage.set('user', null);
    //   return true;
    // } catch (e) {
    //   // this.toast.error('', "Erreur de déconnexion. Contactez l'administrateur", 5000);
    //   return false;
    // }
  }

  async saveToken(res: any) {
    await this.storage.set('token', res.token);
    await this.storage.set('refresh_token', res.refresh_token);
  }

  async getToken() {
    return this.storage.get('token');
  }

  async getRefreshToken() {
    return this.storage.get('refresh_token');
  }

  async saveUser(user) {
    return this.storage.set('user', user)
  }

  async refreshAccessToken(): Promise<Observable<any>> {
    // Call the refresh token endpoint to get a new access token
    const refreshToken = await this.getRefreshToken();
    return await lastValueFrom(this.http.post<any>(
      `${this.apiUrl}api/token/refresh`,
      { 'refresh_token': refreshToken }
    ).pipe(
      tap(async (response) => {
        // Update the access token in the local storage
        await this.saveToken(response)
      }),
      catchError((error) => {
        // Handle refresh token error (e.g., redirect to login page)
        console.error('Error refreshing access token:', error);
        return throwError(error);
      })
    ));
  }
}
