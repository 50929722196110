import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../models/user.model';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    loggedIn = false;
    apiUrl: string = environment.api;

    constructor(
        private http: HttpClient
    ) { }

    // create user asynchronisly in database if not exist
    createIfNotExistFromSocial(socialPLateformName: string, accessToken: string): Observable<any> {
        const headers = { 'content-type': 'application/json' }
        const post = {
            accessToken: accessToken
        }

        switch (socialPLateformName) {
            case 'google':
                return this.http.post(this.apiUrl + 'auth/google-login', post, { 'headers': headers })
        }

    }


    create(user): Observable<any> {
        user = this.userAdaptCreate(user)
        return this.http.post(
            this.apiUrl + 'api/users',
            user
        )
    }

    private userAdaptCreate(user) {
        user.plainPassword = user.password;
        user.phone = user.phone.toString();
        return user;
    }

    editInterests(user: User): Observable<any> {

        let interests = JSON.parse(JSON.stringify(user.interests));
        interests = interests.map(i => '/api/interests/' + i.id);
        return this.http.patch(
            this.apiUrl + 'api/users/' + user.id,
            {
                interests: interests
            }
        )
    }
}
