import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { CommonModule } from '@angular/common';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// ReactiveForms
import { ReactiveFormsModule } from '@angular/forms';

// NgCharts
import { NgChartsModule } from 'ng2-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { IonicStorageModule } from '@ionic/storage-angular';
import { LightboxModule } from 'ngx-lightbox';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';

registerLocaleData(localeFr);

@NgModule({
    declarations: [AppComponent],
    imports: [CommonModule, BrowserModule, IonicModule.forRoot({ mode: 'ios' }), ReactiveFormsModule, AppRoutingModule, NgChartsModule,
        BrowserAnimationsModule,
        LightboxModule,
        IonicStorageModule.forRoot(),
        SocialLoginModule,
        HttpClientModule
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },

        {
            provide: LOCALE_ID,
            useValue: 'fr-FR' // 'de-DE' for Germany, 'fr-FR' for France ...
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        // {
        //     provide: 'SocialAuthServiceConfig',
        //     useValue: {
        //         autoLogin: false,
        //         lang: 'en',
        //         providers: [
        //             {
        //                 id: GoogleLoginProvider.PROVIDER_ID,
        //                 provider: new GoogleLoginProvider(
        //                     '170444372363-0cbr1avmh4cs1mfdb64lb4jrhgrh9lfs.apps.googleusercontent.com'
        //                 )
        //             },
        //             {
        //                 id: FacebookLoginProvider.PROVIDER_ID,
        //                 provider: new FacebookLoginProvider('clientId')
        //             }
        //         ],
        //         onError: (err) => {
        //             console.error(err);
        //         }
        //     } as SocialAuthServiceConfig,
        // }
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
