import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { PublicGuard } from './guards/public.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dahsboard',
    pathMatch: 'full'
  },
  {
    path: 'secure',
    loadChildren: () => import('./pages/secure/secure.module').then(m => m.SecureModule),
    canActivate: [AuthGuard] // Secure all child pages
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/public/welcome/welcome.module').then(m => m.WelcomePageModule),
    // canActivate: [PublicGuard] // Prevent for signed in users
  },
  {
    path: 'signin',
    loadChildren: () => import('./pages/public/signin/signin.module').then(m => m.SigninPageModule),
    // canActivate: [PublicGuard] // Prevent for signed in users
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/public/signup/signup.module').then(m => m.SignupPageModule),
    // canActivate: [PublicGuard] // Prevent for signed in users
  },
  {
    path: 'password-reset',
    loadChildren: () => import('./pages/public/password-reset/password-reset.module').then(m => m.PasswordResetPageModule),
    // canActivate: [PublicGuard] // Prevent for signed in users
  },
  {
    path: 'statistics',
    loadChildren: () => import('./pages/secure/statistics/statistics.module').then(m => m.StatisticsPageModule)
  },
  {
    path: 'dahsboard',
    loadChildren: () => import('./pages/public/dahsboard/dahsboard.module').then(m => m.DahsboardPageModule)
  },
  {
    path: 'wish-detail',
    loadChildren: () => import('./pages/public/wish-detail/wish-detail.module').then(m => m.WishDetailPageModule)
  },
  {
    path: 'wish-search',
    loadChildren: () => import('./pages/public/wish-search/wish-search.module').then(m => m.WishSearchPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/secure/settings/settings.module').then(m => m.SettingsPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
