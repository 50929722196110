import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toasts: any[] = []; 

  constructor(
    public toastController: ToastController
  ) { }

  async success(header: string, message: any, duration: number = 5000, position: any = top) {
    this.presentToast('', message, position, 'success', duration)
  }

  async error(header: string, message: any, duration: number = 5000, position: any = top) {
    this.presentToast(header, message, position, 'danger', duration)
  }

  async info(header: string, message: any, duration: number = 5000, position: any = top) {
    this.presentToast(header, message, position, 'tertiary', duration)
  }

  async warning(header: string, message: any, duration: number = 5000, position: any = top) {
    this.presentToast(header, message, position, 'warning', duration)
  }

  async presentToast(header: string = '', message: any, position: any = 'top', color: string, duration: number, icon?: string) {

    if (!icon) {
      switch (color) {
        case 'success':
          icon = 'checkmark-outline';
          break;
        case 'tertiary':
          icon = 'information-circle-outline';
          break;
        case 'danger':
          icon = 'warning-outline';
          break;
        case 'warning':
          icon = 'warning-outline';
          break;
      }
    }

    const toast = await this.toastController.create({
      header: header,
      message: message,
      duration: duration,
      position: position,
      color: color,
      icon: icon,
      animated: false,
      buttons: [
        {
          text: 'X',
          role: 'cancel',
          handler: () => {
            console.log('Dismiss clicked');
          },
        }]
    });
    this.toasts.push(toast); 
    await toast.present();
  }
  
  closeAllToasts() {
    this.toasts.forEach(toast => {
      toast.dismiss();
    });

    this.toasts = []; // Clear the array after dismissing all toasts
  }
}