// token-interceptor.service.ts

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        private auth: AuthService,
        private router: Router,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.auth.getToken()).pipe(
            mergeMap(token => {
                if (token) {
                    request = this.addToken(request, token);
                }


                return next.handle(request).pipe(
                    catchError((error) => {
                        // Check if the error is due to an expired access token
                        if (error.status === 401 && token) {
                            return this.handleTokenExpired(request, next);
                        }

                        return throwError(error);
                    })
                );
            })
        );
    }

    private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
        if (request.url.indexOf('api/token/refresh') < 0) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                },
            });
        }
        return request;
    }

    private handleTokenExpired(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        // Call the refresh token endpoint to get a new access token
        return from(this.auth.refreshAccessToken()).pipe(
            switchMap(() => from(this.auth.getToken())),
            switchMap((newAccessToken: string) => {
                console.log('Retrying request with new token:', newAccessToken);
                return next.handle(this.addToken(request, newAccessToken));
            }),
            catchError((error) => {
                // Handle refresh token error (e.g., redirect to login page)
                console.error('Error handling expired access token:', error);
                this.auth.signout();
                this.router.navigateByUrl('/signin');
                return throwError(error);
            })
        );
    }
}